.rideSummary {
  padding: 0;
  margin: 0;
  li {
    position: relative;
    display: flex;
    padding: 8px;
    line-height: 40px;
    justify-content: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    @media (max-width: 767px) {
      transform: scale(.75);
       margin-bottom: -20px;
      .parameter {
        width: 140px;
        margin: 0 !important;
      }
    }
    .setDirection {
      width: 100px;
      line-height: 14px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      height: 35px;
      justify-content: center;
    }
    .parameter {
      display: flex;
      padding-left: 10px;
      margin: 0px 10px;
      align-items: center;
      .number {
        width: 40px;
        text-align: center;
        font-size: 36px;
        opacity: 1;
        position: relative;
        font-weight: 500;
        line-height: 36px;
        margin: 0px 10px 0px 15px;
      }
      .direction {
        margin: 0px 10px 0px 15px;
      }
      span {
        font-weight: 500;
        margin-right: 10px;
        display: block;
        opacity: 0;
        top: 0;
        position: absolute;
        transition: .3s;
      }
      label {
        padding-top: 0;
        margin-bottom: 0;
        position: relative;
      }
      &.ccw {
        line-height: 40px;
        label {
          padding-top: 10px;
          margin-bottom: -10px;
        }
        span {
          top: -10px;
          opacity: 1 !important;
        }
      }
    }
  }
  flex: 1;
  li {
    display: flex;
  }
}
