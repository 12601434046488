@keyframes slideUp {
  from {
    transform: translateY(610px);
  }

  50% {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0px);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-61px);
  }

  to {
    transform: translateY(0px);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-20px);
  }

  to {
    transform: translateX(0px);
  }
}


@keyframes zoom {
  from {
    transform: scale(.9);
  }

  50% {
    transform: scale(1.5);
  }

  to {
    transform: scale(1);
  }
}

.page-leaderboards {

  .locationSelectWrapper {
    margin-right: 50px;
  }

  @media (max-width: 500px) {
    padding-right: 0;
    padding-left: 0;
    .tabsDateRange {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
}

table.leaderboard {
  max-width: 768px;
  margin: 20px auto;
  tr {
    animation: slideDown .4s;
    animation-timing-function: ease;
    display: flex;
    height: 80px;
    align-items: center;
    @media (max-width: 490px) {
      height: 60px;
    }
  }
  tr:first-child {
    border-top: none;
    animation: slideUp .4s;
    .trophy {
      background-image: url('../img/trophy-white.svg');
    }
    td {
      color: white;
    }
  }

  tr:last-child td {
    border-bottom: none;
  }

  td {
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    border: none !important;
    @media (max-width: 500px) {
      font-size: 12px;
      padding: 5px;
    }
    &.rank {
      width: 20px;
      font-size: 28px;
      margin-right: 10px;
      padding-left: 25px;
      @media (max-width: 500px) {
        padding-left: 15px;
        font-size: 16px;
      }
    }
    &.location, &.group {
      flex: 1;
      @media (max-width: 600px) {
        font-size: 14px;
      }
      @media (max-width: 490px) {
        display: none;
      }
    }
    &.startTime {
      flex: 1;
      @media (max-width: 490px) {
        font-size: 12px;
      }
    }
    &.name {
      padding-left: 20px;
      font-size: 16px;
      line-height: 18px;
      flex: 1;
      @media (max-width: 600px) {
        font-size: 14px;
      }
      @media (max-width: 490px) {
        font-size: 12px;
        padding-left: 10px;
      }
    }
    &.score {
      width: 150px;
      font-size: 28px;
      font-weight: bold;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (max-width: 500px) {
        padding-right: 25px;
        width: 125px;
        font-size: 18px;
      }
      .count {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: zoom 1s;
        margin-right: 15px;
        margin-top: -1px;
        span {
          font-size: 12px;
          margin-right: -17px;
          @media (max-width: 500px) {
            font-size: 10px;
          }
        }
      }
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      @media (max-width: 500px) {
        position: relative;
        margin-right: 8px;
      }
    }
  }

}