
@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.startRideAction {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255,255,255,.7);
  align-items: center;
  bottom: 80px;
  .column {
    .button {
      width: 200px;
    }
  }
  .coreScore {
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    .star {
      width: 15px;
      height: 15px;
      margin-left: 10px;
    }
    .score {
      font-size: 24px;
      margin-left: 5px;
      font-weight: bold;
    }
  }
  .button {
    margin: 15px;
  }
  @media (min-width: 768px) {
    bottom: 101px;
    .button {
      margin: 25px;
    }
  }
}

.addSet {
  margin-top: 25px;
}

.rideSummary {
  border-top: 1px solid #e8e8e8;
}

.rideConfig, .rideSummary {
  padding-bottom: 90px;
  margin: 0 -20px;

  ol {
    margin: 0px;
    padding: 0;
    li {
      overflow: hidden;
      list-style-type: none;
      .set {
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        padding: 30px 15px;
        line-height: 40px;
        justify-content: space-around;
        align-items: center;
        font-size: 18px;
        text-transform: uppercase;
        animation: slideIn .5s;
        align-items: center;
        @media (max-width: 767px) {
          font-size: 14px;
          padding: 10px;
        }
      }

      .parameter {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        line-height: 20px;
        @media (max-width: 767px) {
          height: 70px;
        }
        @media (max-width: 590px) {
          flex-direction: column;
        }
        input[type=checkbox] {
          display: none;
        }
        label {
          margin: 0 10px;
        }
        &.parameter-direction {
          cursor: pointer;
          flex-direction: row;
          .direction-label {
            user-select: none;
            line-height: 0;
            transition: line-height .3s;
            :first-child {
              opacity: 0;
              transition: opacity .3s;
            }
            &.direction-ccw-label {
              line-height: 1;
              :first-child {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.delete {
  background-image: url('../img/x.svg');
  background-repeat: no-repeat;
  background-position: center center;
  height: 35px;
  width: 35px;
  border: 2px solid #c8c8c8;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  margin-left: 10px;
  flex-shrink: 0;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
  &.active {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }
}

.modal.confirm {
  padding-bottom: 75px;

  .evaluationComplete {
    ol {
      li {
        align-items: center;
        font-size: 22px;
        height: 36px;
      }
    }
  }
  .coreScore {
    margin-bottom: 20px;
    margin-top: -50px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    justify-content: center;
    .icon {
      margin: 0 5px 0 10px;
      width: 20px;
      height: 20px;
    }
    .score {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .rideSummary {
    flex: 0;
    margin-bottom: 50px;
    li {
      padding: 10px 0px;
    }
  }

  footer.flexRow {
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
  }

  .newNote {
    width: 600px;
    @media (max-width: 650px) {
      width: 90vw;
      margin: 0;
      textarea {
        font-size: 12px;
      }
    }
    fieldset {
      border: 0px;
    }
    textarea {
      color: white;
    }
    background-color: rgba(0,0,0,0);
    margin: 25px auto;
  }

  .noteConfirm {
    pointer-events: none;
  }

  .questionnaireConfirm {
    .empty {
      opacity: .35;
    }
  }

  .assessment {
    margin-bottom: 50px;
    pointer-events: none;
  }

  .assessmentParameter {
    header {
      color: white;
    }
    input {
      color: white !important;
    }
    .donutChart {
      background-color: rgba(0,0,0,0) !important;
      svg path {
        stroke-width: 3px;
      }
      &:after {
        background-color: rgba(0,0,0,0);
      }
    }
  }

  ol {
    flex: 1;
    margin-top: 30px;
    li {
      font-size: 14px;
      &:first-child {
        strong {
          margin-top: 0px;
        }
      }
      strong {
        display: block;
        line-height: 16px;
        margin-top: 15px;
        margin-bottom: 5px;
      }
      span {
        display: block;
        line-height: 14px;
      }
    }
  }

}

.evaluationOverview {
  max-width: 500px;
  margin: 0px auto;
  line-height: 22px;
  padding: 0 5px 55px 5px;
  p {
    margin: 15px 0;
  }

  ol {
    padding-bottom: 50px;
    @media (max-width: 500px) {
      margin-right: 20px;
    }
    li {
      border-radius: 6px;
      background-color: #ebebeb;
      margin: 20px 10px;
      padding: 10px;
      position: relative;
      top: 0;
      transition: .2s;
      &.slideUp {
        top: -31px;
      }
      &.slideDown {
        top: 31px;
      }
    }
  }
  .arrowUp, .arrowDown {
    cursor: pointer;
    float: right;
    margin-right: -35px;
    width: 15px; 
    height: 15px; 
    display: none;
    background-image: url('../img/caret-green.svg');
    background-size: contain;
    background-repeat: no-repeat;
    &.active {
      display: block;
    }
  }

  .arrowUp {
    margin-top: -5px;
  }
  .arrowDown {
    margin-top: -10px;
    transform: rotate(180deg);
  }

  ul {
    li {

      margin: 25px 0;
      strong {
        display: block;
        text-transform: uppercase;
      }
    }
  }
}

.averageAngle {
  display: flex;
  flex-direction: column;
  margin: -4px 20px 0;
  animation: fadeInLeft 1s;
  @media (max-width: 767px) {
    padding-bottom: 30px;
    width: 100%;
    .parameter-input {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .stepper {
        display: block;
        width: 30px;
        height: 30px;
      }
    }
  }
  label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    font-size: 28px;
    text-align: center;
    display: inline-block;
    height: 55px;
    border: 0;
    width: 55px;
    -moz-appearance: textfield;
  }

  .stepper {
    width: 20px;
    height: 20px;
    margin: 0;
  }

  input {
    font-size: 36px;
    line-height: 36px;
    width: auto;
    font-family: 'Cairo', sans-serif;
    text-align: center;
    display: inline-block;
    height: 52px;
    margin: 0;
    border: 0;
    width: 65px;
  }
}

.rideTemplateSelect {
  max-width: 1080px;
  margin: 0 auto;
  @media (max-width: 500px) {
    margin: 0 20px;
  }
  .rideTemplate {
    cursor: pointer;
    padding: 0 20px;
    @media (max-width: 500px) {
      padding: 0;
    }
  }

  .rideContent {
    border-bottom: 1px solid rgba(0,0,0,.2);
    padding: 20px;  
    .flexRow {
      justify-content: center;
      align-items: center;
      .diagram {
        margin: 0 20px;
        border-radius: 6px;
        width: 200px;
        height: 65px;
        background-repeat: no-repeat;
        background-size: 200px;
        &[data-template="steadyBurn"] {
          background-image: url('../img/template-5x5.svg');
        }
        &[data-template="middlePassage"] {
          background-image: url('../img/template-cool-down.svg');
        }
        &[data-template="theGrind"] {
          background-image: url('../img/template-deep-dive.svg');
        }
      }
      .rideSummary {
        padding: 10px 0;
        margin-bottom: 15px;
        border-top: 0px;
        .parameter:first-child {
          width: 125px;
        }
        @media (max-width: 768px) {
          li, .parameter {
            padding: 5px 0;
          }
        }
      }
    }
    header {
      font-size: 20px;
      flex: 1;
      span {
        font-size: 14px;
        color: #777;
        display: block;
        margin-top: -5px;
      }
    }
    @media (max-width: 500px) {
      padding: 10px;
      .flexRow .diagram {
        width: 80px;
        height: 25px;
        background-size: 80px;
        margin: 0 10px 0 -10px;
      }
      .coreScoreWrapper {
        padding-top: 0px;
        font-size: 14px;
        .score {
          margin-top: 0;
        }
        .icon {
          width: 15px;
          height: 15px;
          margin-right: 3px;
          margin-bottom: -1px;      
        }
        h3 {
          font-size: 12px;
        }
      }
      header {
        font-size: 16px;
        span {
          font-size: 12px;
        }
      }
    }
    .coreScoreWrapper {
      animation: none;
    }
  }
}