.userSearch {
  display: flex;
  padding: 10px;
  left: 0;
  background-color: white;
  right: 0;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  top: 100px;
  transition: top .3s cubic-bezier(0.470, 0.000, 0.745, 0.715);
  &.slide {
    top: 250px;
  }
  .addUser {
    cursor: pointer;
    span {
      height: 50px;
      display: flex;
      width: 50px;
      justify-content: center;
      align-items: center;
      font-size: 35px;
    }
    opacity: .8;
    transition: opacity .3s;
    &:hover {
      opacity: 1;
    }
  }

  @media (min-width: 1080px) {
    max-width: 1080px;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    top: 90px;
    padding: 0;
  }
  .inputSearch {
    max-width: 1040px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      background-position: center left 5px;
      padding: 0px 10px 0px 30px !important;
      input {
        font-size: 16px;
      }
    }
  }
  .search {
    margin: 0;
  }
  fieldset {
    border: none;
  }
}

@keyframes fadeIn {
  from { opacity: 0; top: 10px; }
  to   { opacity: 1; top: 0px;}
}

.groupSelectWrapper {
  display: flex;
  justify-content: center;
  min-height: 48px;
}
.groupSelect {

  position: relative;

  [role="button"] {
    color: white;
    padding: 0 25px 0 0;
    font-size: 18px;
    overflow: visible;
  }
  svg {
    fill: white;
    top: -2px;
  }
  &:after, &:before {
    display: none;
  }
}

.addNewPatient {
  margin-top: -75px;
  position: relative;
  animation: fadeIn .5s;
  @media (max-width: 768px) {
    margin-top: -50px;
  }

}

.page.users {
  padding-top: 175px;
  @media (max-width: 1024px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (max-width: 767px) {
    padding-top: 140px;
  }

  &.empty {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    padding-top: 200px;
  }

}

.patientTable {
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  font-family: 'Cairo', sans-serif;

  @media (max-width: 1024px) {
    border-radius: 0px;
  }

  @media (max-width: 767px) {
    display: block !important;

    thead, tbody {
      display: block !important;
      max-width: 100%;
      font-size: 10px;
      tr {
        height: 50px;
        display: flex !important;
        max-width: 100%;
        th, td {
          display: flex !important;
          align-items: center;
          a {
            width: 100%;
            padding: 25px 5px;
          }
          &:first-child {
            padding-right: 47px;
          }
          &:last-child {
            width: 100px;
            padding-right: 0px;
            justify-content: flex-start;
          }
          &:nth-child(2) {
            flex: 1;
          }
        }
      }
    }
    td {
      font-size: 16px !important;
    }

  }
  .columns {
    height: 50px;
    border: 1px solid blue;
  }
  th {
    padding: 10px;
    font-size: 18px;
    text-align: left;
    @media (max-width: 500px) {
      font-size: 16px;
      padding: 5px;
      &:first-child {
        width: 60px;
      }
    }
  }

  tbody td {
    cursor: pointer;
    padding: 0 !important;
    @media (max-width: 500px) {
      &:first-child {
        width: 113px;
      }
    }
    a {
      text-decoration: none;
      color: inherit;
      display: block;
      padding: 25px 10px;
      @media (max-width: 500px) {
        display: flex;
        font-size: 12px;
        align-content: center;
        padding: 5px;
        line-height: 12px;
      }
    }

  }
}

.rideTotals {
  @media (max-width: 767px) {
    margin-top: -50px
  }
  .loading {
    transform: scale(.15);
    position: relative;
    left: -10px;
    top: 5px;
  }
  margin-top: -25px;
  padding: 0;
  height: 83px;
  align-items: flex-end;

  li {
    list-style-type: none;
    text-align: center;
    text-transform: uppercase;
    width: 125px;
    span {
      display: block;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: -15px;
    }
    @media (max-width: 767px) {
      font-size: 10px;
      span {
        margin-bottom: -10px;
        font-size: 24px;
      }
    }

  }
}

.userNewForm {
  max-width: 550px;
  margin: 0 auto;
  .form-group {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    .form-group {
      margin-bottom: 30px;
    }
  }

}

.dateRange {
  align-items: center;
  margin-top: 20px;
  .icon {
    margin-bottom: -5px;
  }
  .form-group {
    margin: 0 20px;
    @media (max-width: 500px) {
      input {
        font-size: 14px;
      }
    }
  }
}

.tooltip, .legend {
  header {
    font-size: 18px;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    display: flex;
    align-items: center;
    list-style-type: none;
  }
  .value {
    font-weight: bold;
    font-size: 24px;
    flex: 1;
    text-align: right;
  }
  i {
    font-style: normal;
    position: absolute;
    display: block;
    right: -4px;
    font-size: 18px;
    margin-top: -4px;
    font-weight: bold;
  }
  span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    margin-right: 5px;
  }
}

.switchWrapper {
  align-items: center;
  margin-bottom: 20px;
  margin-top: -10px;
  .active {
    font-weight: bold;
  }
  .switch {
    > span:first-child {
      span {
        color: white;
      }
    }
    > span:nth-child(2) {
      background-color: #000 !important;
      opacity: .38 !important;
    }
  }
  span {
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase;
  }
}
.legend {
  span {
    margin-right: 20px;
  }
}

.legendKey {
  margin-right: 5px !important;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}


@keyframes pulse {
  from {
    -webkit-transform: scale3d(.8, .8, .8);
    transform: scale3d(.8, .8, .8);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.rideDetails {
  align-items: baseline;
  border-bottom: 1px solid #e8e8e8;
  margin: 0 -20px;
  padding-bottom: 30px;
  @media (max-width: 500px) {
    margin: 0 auto;
    padding-bottom: 0px;
    > .flexRow {
      flex-direction: column;
      align-items: center;
    }
    .rideDetailSelectWrapper {
      margin-bottom: 15px;
      margin-right: 25px;
      width: 100%;
      .rideDetailSelect {
        width: 100%;
      }
      > div {
        width: 100%;
      }
    }
  }
  .rideDetailSelectWrapper {
    margin: 0 20px;
    .rideDetailSelect {
      width: 175px;
      @media (max-width: 500px) {
        width: 100%;
        margin: 20px 0;        
      }
      &:before {
        display: none;
      }  
    }
  }
  .rideMode {
    .switchWrapper {
      margin-top: 0;
    }
  }
}

.angleSelectWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 768px) {
    margin-bottom: -15px;
  }
  h2 {
    margin-bottom: 5px;
  }
  .icon {
    position: relative;
    top: 8px;
    margin-right: 15px;
    width: 15px;
    height: 15px;
  }
  .angleSelect {
    width: 115px;
  }
}

.coreScoreWrapper {
  animation: pulse 1s;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 10px;
  .scoreItem {
    margin: 5px 40px;
  }
  .icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  .score {
    margin-top: -15px;
    font-size: 36px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  footer {
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.dateEditWrapper {
  justify-content: flex-start;
  position: absolute;
  background-color: white;
  top: 15px;
  left: 30px;
  padding: 5px;
}
