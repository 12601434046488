.navbar {
  text-align: center;
  display: flex;
  align-items: center;
  .logout {
    position: absolute;
    right: 15px;
  }
  .brand {
    display: flex;
    position: absolute;
    left: 15px;
    img {
      width: auto;
      height: 30px;
      align-items: center;
      margin-right: 10px;
      @media (max-width: 500px) {
        height: 20px;
      }
    }
  }
  .appTitle {
    font-size: 12px;
    @media (max-width: 500px) {
      font-size: 10px;
      margin-left: -5px;
    }
  }
  h1 {
    flex: 1;
    font-size: 28px;
    position: relative;
    height: 52px;
    box-sizing: border-box;
    left: -50px;
    @media (max-width: 767px) {
      font-size: 22px;
    }
    @media (max-width: 440px) {
      font-size: 20px;
      transform: translateY(5px);
    }
  }
}

.appBar {
  box-shadow: none !important;
  height: 100px;
  overflow: hidden;
  @media (max-width: 767px) {
    height: 90px;
  }
}

.navTabsWrapper {
  margin: 0 auto;
}

.tabBar {
  .tabsIndicator {
    display: none;
  }
  @media (max-width: 768px) {
    svg {
      margin-top: -10px;
      margin-bottom: -10px;
      height: 20px;
    }
  }
}

.inset {
  background-color: #ebebeb;
  padding: 20px;
  margin: 20px auto;
  border-radius: 6px;
  .rideSummary {
    border-top: 0px;
    padding-bottom: 0;
  }
  @media (max-width: 1024px) {
    margin: 20px -20px;
    border-radius: 0px;
  }
  h2 {
    margin: 0px;
  }
}

.navTabs {
  justify-content: center;
  display: flex;
  left: 0;
  bottom: 0;
  transition: left .4s;
  position: relative;
  @media (max-width: 766px) {
    bottom: 5px;
    &.firefox {
      top: -10px;
    }
  }
  @media (max-width: 440px) {
    &.firefox {
      top: -20px;
    }
  }
  button {
    font-size: 18px;
    text-transform: none;
    display: inline-block;
    padding: 0px 10px;
    flex-shrink: 0;
    @media (max-width: 768px) {
      font-size: 2vw;
      margin-right: -10px;
    }
    @media (max-width: 500px) {
      font-size: 16px;
      padding: 0 5px;
    }
  }
  &.tabless {
    .tabsIndicator {
      display: none;
    }
  }
  .tabsIndicator {
    display: flex;
    height: 10px;
    margin: 0 auto;
    justify-content: center;
    background-color: rgba(0,0,0,0);
    @media (max-width: 767px) {
      height: 15px;
    }
    &:after {
      display: inline-block;
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
    }
  }
}

.tabsDateRange {
  display: flex;
  justify-content: center;
  margin-top: -10px;
  .tabs {
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    min-height: inherit !important;
    @media (max-width: 500px) {
      margin: 0px -20px;
      width: 100%;
    }
  }
  [role="tablist"] {
    height: 30px;
  }
  [role="tab"] {
    border-left: 1px solid #c8c8c8;
    min-height: inherit !important;
    &:first-child {
      border: none;
    }
    @media (max-width: 500px) {
      width: 25%;
      font-size: 2.53vw;
    }
  }
  span {
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .indicator {
    display: none;
  }
}

.tabsPage {
  display: flex;
  justify-content: center;
  max-width: 768px;
  margin: 20px auto;
  [role="tab"] {
    flex: 1;
  }
  .MuiTabs-flexContainer-38 {
    width: 100%;

  }
  @media (max-width: 500px) {
    margin-top: 10px;
    [role="tab"] {
      font-size: 10px;
    }
  }
  .indicator {
    display: none;
  }
}

.tabsSecondary {
  color:#333;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto 15px auto;
  border-radius: 100px;
  min-height: 0px !important;
  [role="tablist"] {
    overflow-x: visible !important;
  }

  [aria-selected="true"] {
    color: white;
    opacity: 1;
  }

  [type="button"] {
    opacity: .8;
    width: 80px;
    height: 40px;
    min-height: 0;
    transition: .4s;
    @media (max-width: 475px) {
      font-size: 10px;
    }
  }

  .tabsIndicator {
    height: 100%;
    z-index: -1;
  }

}

button.announcements {
  border: 0px;
  background-color: rgba(0,0,0,0);
  position: fixed;
  cursor: pointer;
  right: 50px;
  top: 15px;
  z-index: 1100;
  &:focus {
    outline:0;    
  }
  .count {
    display: block;
    line-height: 14px;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    font-size: 8px;
    color: white;
    position: relative;
    left: 13px;
    top: -4px;
  }
}
