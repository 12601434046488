.page.patientNotes {
  padding-top: 115px;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 475px) {
    padding-top: 100px;
  }
  .tabsSecondary {
    max-width: 767px;
    margin: 10px auto 25px auto;
  }

  .subPage {
    margin-top: -100px;
    padding-top: 80px;
  }

  .inset {
    button {
      margin: 25px;
      @media (max-width: 600px) {
        margin: 0 15px;
      }
    }
  }

  .newNote {
    max-width: 600px;
    background-color: white;
    margin: 25px auto;
    display: flex;
  }

}

.patientQuestionnaire {
  .inset {
    @media (max-width: 600px) {
      button {
        margin: 25px !important;
      }
    }
  }
}

.questionnaire {

  max-width: 550px;
  margin: 20px auto;

  p {
    margin: 20px auto;
    line-height: 24px;
  }

  ol {
    margin: 10px auto;
    li {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      strong {
        line-height: 20px;
      }
      span {
        line-height: 16px;
      }
      label {
        margin-bottom: -8px;
        display: flex;
        @media (max-width: 500px) {
          margin-bottom: 5px;
        }
      }
    }
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.invalidAssessment {
  text-align: center;
  font-style: italic;
  font-size: 14px;
  animation: fadeIn .5s;
}

.assessment {
  padding: 20px 0;
  max-width: 620px;
  margin: 0 auto;
  justify-content: space-between;
  @media (max-width: 600px) {
    padding: 10px 0;
    justify-content: center;
  }
}

.assessmentParameter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 145px;
  flex-direction: column;
  @media (max-width: 600px) {
    transform: scale(0.85);
    width: 70px;
    flex: 1;
  }

  header {
    text-align: center;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.75);
    font-weight: bold;
    font-size: 14px;
    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 12px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }

  input {
    background: none;
    border: 0px;
    text-align: center;
    font-size: 28px;
    font-family: 'Cairo';
    width: 65px;
    @media (max-width: 1024px) {
      height: 50px;
      font-size: 24px;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .inputWrapper {
    display: flex;
    justify-content: center;
    right: 0;
    margin-top: -70px;
    z-index: 1;
    align-items: center;
    width: 70px;
    height: 60px;
  }

  .donutChart {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    position: relative;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      position: absolute;
      path {
        transition: .4s;
      }
    }
    &:after {
      background-color: #ebebeb;
      display: block;
      content: '';
      width: 50px;
      height: 50px;
      position: absolute;
      top: 5px;
      border-radius: 100%;
      left: 5px;
    }

  }

}
