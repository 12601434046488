.totalRidesContainer {
  max-width: 540px;
  margin: 20px auto;

  .loading {
    transform: scale(.2);
    margin-top: -20px;
  }

 .rides {
    flex: 1;
    justify-content: space-between;
    padding: 25px 20px;
    overflow: hidden;
    align-items: center;
    border-radius: 10px 0 0 10px;
    text-transform: uppercase;
    font-size: 16px;
    @media (max-width: 560px) {
      font-size: 14px;
      padding: 10px;
    }
    span {
      font-size: 12px;
      display: block;
      margin-bottom: -10px;
    }
  }

  .owed {
    padding: 25px 20px;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    flex: 1;
    border-radius: 0 10px 10px 0;
    @media (max-width: 560px) {
      font-size: 12px;
      padding: 10px;
    }
    span {
      font-size: 10px;
    }
  }

}

.rideTotal, .owedTotal {

  span {
    font-size: 12px;
    position: relative;
    top: -3px;
    left: -2px;
    font-weight: normal;
  }
  font-weight: bold;
  font-size: 24px !important;
}

.monthlyTotals {
  max-width: 520px;
  margin: 0 auto;
  padding: 0;
  td {
    padding: 5px 0;
    border: 0;
    width: 25%;
    &:nth-child(2) {
      text-align: right;
      padding-right: 10px;
    }
    &:nth-child(3) {
      padding-left: 10px;
    }
  }
  span {
    font-size: 12px;
    text-transform: uppercase;
  }
  .rideTotal, .owedTotal {
    @media (max-width: 560px) {
      width: 10% !important;
      padding-right: 5px !important;
    }
  }
  .owedTotal {
    text-transform: uppercase;
    text-align: right;
  }
  li {
    align-items: center;
    justify-content: space-between;
  }
  .rides {
    text-transform: uppercase;
    font-size: 16px;
    span {
      font-size: 12px;
      display: block;
      margin-bottom: -10px;
      @media (max-width: 560px) {
        font-size: 10px;
      }
    }
    @media (max-width: 560px) {
      strong {
        font-size: 14px;
      }
    }

  }
}

.locationInfo {
  justify-content: center;
  max-width: 520px;
  margin: 20px auto;
  align-items: center;
  span {
    font-size: 12px;
    text-transform: uppercase;
  }
  strong {
    font-size: 24px;
    margin: 0 15px;
    span {
      font-weight: normal;
      position: relative;
      top: -4px;
      left: -2px;
    }
  }
}
.locationSelectWrapper {
  text-align: center;
  margin-top: -20px;
  margin-bottom: 20px;
  [role="button"] {
    padding-right: 10px;
    font-size: 16px;
    &:focus {
      background: white;
    }
  }
  .locationSelect {
    &:after, &:before {
      opacity: 0;
    }
  }
  svg {
    top: 4px;
    right: -15px;
  }
}

.brand-blue {
  background-image: url('../img/brand-blue.png');
}

.reportSelect {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  @media (max-width: 440px) {
    padding: 10px 20px;
  }
  .icon {
    display: block;
    width: 50px;
    height: 50px;
  }
  .flexRow {
    text-transform: uppercase;
    flex-direction: column;
    font-weight: bold;
    align-items: center;
  }
  p {
    text-align: center;
    max-width: 300px;
    line-height: 18px;
    font-size: 14px;
    margin: 10px auto 0;
  }
  a {
    transition: background .3s;
    background: white;
    width: 300px;
    margin: 25px auto;
    text-decoration: none;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    padding: 35px;
    @media (max-width: 440px) {
      width: 100%;
      margin: 15px auto;
      box-sizing: border-box;
    }
    &:hover {
      background: #e8e8e8;
    }
  }
}

.flexRow.download {
  padding-bottom: 150px;
  margin-top: -125px;
  @media (max-width: 768px) {
    button {
      width: 225px;
    }
  }
}

