@keyframes slideUp {
  from {
    transform: translateY(610px);
  }

  50% {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0px);
  }
}

.page.patientProfile {
  margin-top: 10px;
}

.patientProfileForm {
  max-width: 600px;
  margin: 0 auto;
  label {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }

  @media (max-width: 500px) {
    footer.flexRow {
      flex-direction: row;
      margin-top: 15px;
    }
    .flexRow {
      flex-direction: column;
      .form-group {
        margin: 12px 20px;
        width: auto;
      }
    }
  }

  .preferences {
    max-width: 590px;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;    
    color: #777;
  }

  h2 {
    margin-bottom: 0;
  }
  .heightWeight {
    .heightWrapper, .weightWrapper {
      display: flex;
      flex: 1;
    }
    .weightWrapper {
      .form-group {
        width: 230px;
      }
    }
    span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
      position: relative;
      left: -10px;
      bottom: -40px;
    }
  }
  .form-group {
    margin: 20px;
    width: 260px;
    &.inputHeight {
      flex: 1;
      width: auto;
      &.inches {
        label {
          opacity: 0;
        }
      }
    }
  
    input:disabled, select:disabled {
      color: black !important;
    }
    &.disabled > div:before {
      border-bottom: 0px !important;
    }
    &.disabled [role="button"] {
      color: rgb(153, 37, 37) !important;
    }
  }

  .waiverStatus {
    p {
      color: #777;
      display: flex;
      align-items: center;
      flex: 1;
    }
    a {
      text-decoration: none;
      color: white;
      display: block;
      margin: 20px;
    }
    .icon {
      background-image: url('../img/i-waiver.svg');
      width: 20px;
      height: 30px;
      margin-right: 5px;
      margin-left: 20px;
    }  
  }

  footer {
    margin-top: 50px;
    animation: slideUp .4s;
    button {
      margin: 0px 25px;
    }
  }


}